import { z } from "zod";
import ModalDialog from "../../../layout/modal-dialog";
import { Form } from "../../../layout/form/form";
import { EmailField } from "../../../layout/form/email-field";
import { useForm } from "../../../hooks/useForm";
import { useMutation } from "@tanstack/react-query";
import { createUserCompany } from "../../../user-companies/actions/create-user-company";
import { getAuthTokenNoThrow } from "../../../services/auth-header";
import ButtonNeoGen from "../../../layout/button-neogen";
import { TextField } from "../../../layout/form/text-field";
import { createUser } from "../../../jason-proof-of-concept/users/actions/create-user";
import { User } from "../../../jason-proof-of-concept/users/domain/user";
import { SelectField } from "../../../layout/form/select-field";
import { createMagicLink } from "../../../magic-links/actions/create-magic-link";
import { MagicLinkTypes } from "../../../magic-links/domain/magic-link";
import { useAuth } from "../../../auth/use-auth";
import { CheckboxField } from "../../../layout/form/checkbox-field";
import { PasswordField } from "../../../layout/form/password-field";
import authService from "../../../services/auth.service";
import { roleAssignments } from "../../../services/role-group.service";
import { useEffect } from "react";

const inviteFormData = z.object({
    email: z.string().email(),
    roleId: z.number(),
    firstName: z.string(),
    lastName: z.string(),
    phone: z.string(),
    inviteToSystem: z.boolean().optional(),
    preSetPassword: z.boolean().optional(),
    password: z.string().min(8).optional(),
    confirmPassword: z.string().min(8).optional(),
});

type InviteFormData = z.infer<typeof inviteFormData>;

export const AddUserToCompanyModal = ({
    onClose,
    companyId,
    onUserAdded,
    companyRoles,
}: {
    onClose: () => any;
    companyId: number;
    onUserAdded: (user: User) => any;
    companyRoles: NonNullable<User["companyRoleGroups"]>;
}) => {
    const auth = useAuth();
    const authedUser = auth.expectUser();
    const inviteForm = useForm({ schema: inviteFormData });
    const authToken = getAuthTokenNoThrow() || "no-token";

    useEffect(() => {
        inviteForm.setValue("inviteToSystem", true);
        inviteForm.setValue("preSetPassword", true);
    }, []);

    const addUserMutation = useMutation({
        mutationFn: async (data: InviteFormData) => {
            const now = new Date();
            if (data.password !== data.confirmPassword) {
                throw new Error("Passwords do not match");
            }
            const user = await createUser({
                authToken,
                data: {
                    email: data.email,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    phone: data.phone,
                    userStatus: data.inviteToSystem ? "invited" : undefined,
                    invitedAt: data.inviteToSystem ? now : undefined,
                    ...(data.preSetPassword && data.password
                        ? { password: data.password, userStatus: "active", accountSetupAt: now }
                        : {}),
                },
            });
            const userCompany = await createUserCompany({
                authToken,
                data: { userId: user.id, companyId, roleId: data.roleId },
            });
            if (data.inviteToSystem && !data.preSetPassword) {
                await createMagicLink({
                    authToken,
                    data: {
                        type: MagicLinkTypes.userInvite,
                        createdById: authedUser.id,
                        data: {
                            firstName: data.firstName,
                            lastName: data.lastName,
                            email: data.email,
                            phone: data.phone,
                        },
                    },
                });
            }
            return { user, userCompany };
        },
    });

    const handleSubmit = async (data: InviteFormData) => {
        const result = await addUserMutation.mutateAsync(data);
        onUserAdded(result.user);
    };

    const usersRoleGroups = (authService.getCurrentUser()?.user?.roleGroups || []) as any[];
    const roleGroupsIds = usersRoleGroups.reduce<number[]>((acc, roleGroup: any) => {
        return [...acc, ...(roleAssignments?.[roleGroup.id] || [])];
    }, []);
    const finalRoleGroups = companyRoles.filter((rg) => roleGroupsIds.includes(rg.id || 9999));

    const options = finalRoleGroups.map((role) => ({ label: role.name || "", value: role.id as number }));

    const values = inviteForm.watch();

    return (
        <ModalDialog title="Add user to company" close={onClose} show showOk={false} showCancel={false}>
            <p className="px-4 text-gray-400 text-center">Add a new user to this company.</p>
            <div className="pt-6">
                <Form onSubmit={inviteForm.handleSubmit(handleSubmit)} error={addUserMutation.error as any}>
                    <SelectField label="Select role" {...inviteForm.getFieldProps("roleId")} options={options} />
                    <TextField label="First name" {...inviteForm.getFieldProps("firstName")} />
                    <TextField label="Last name" {...inviteForm.getFieldProps("lastName")} />
                    <EmailField autoComplete="off" label="Email address" {...inviteForm.getFieldProps("email")} />
                    <TextField label="Phone number" {...inviteForm.getFieldProps("phone")} />
                    <CheckboxField label="Invite to system" {...inviteForm.getFieldProps("inviteToSystem")} />
                    {values.inviteToSystem && (
                        <>
                            <CheckboxField label="Pre-set password" {...inviteForm.getFieldProps("preSetPassword")} />
                            {values.preSetPassword && (
                                <>
                                    <PasswordField label="Password" {...inviteForm.getFieldProps("password")} />
                                    <PasswordField
                                        label="Confirm password"
                                        {...inviteForm.getFieldProps("confirmPassword")}
                                    />
                                </>
                            )}
                        </>
                    )}
                    <ButtonNeoGen block type="submit" disabled={addUserMutation.isLoading}>
                        Add user
                    </ButtonNeoGen>
                </Form>
            </div>
        </ModalDialog>
    );
};
