import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import fileService from "../../../services/file.service";
import fileUploadService from "../../../services/file.service";
import PageDescription from "../../../layout/page-description";
// import PrintPre from "../../layout/print-pre";
import TableNeogen from "../../../layout/table-neogen";
import Loader2 from "../../utilities/Loader2";
import AddFile from "./modals/add-file";
import { useInfiniteQuery } from "@tanstack/react-query";
import { File } from "../../../files/domain/file";
import { usePaginatedResultItems } from "../../../hooks/usePaginatedResults";
import { PaginatedView } from "../../../layout/PaginatedView";

export default function Files({ itemsPerPage = 10 }: { itemsPerPage?: number }) {
    const [showAddFile, setShowAddFile] = useState(false);
    const queryCache = useQueryClient();

    type QueryKey = ["file-uploads"];

    const fetchFiles = async ({
        pageParam,
        queryKey,
    }: {
        pageParam?: { hasNextPage: boolean; startAt?: number };
        queryKey: QueryKey;
    }) => {
        const [_query] = queryKey;
        const { hasNextPage, startAt = 0 } = pageParam ?? { hasNextPage: true, startAt: 0 };
        try {
            if (!hasNextPage) {
                return { pages: [], total: 0, hasNextPage: false };
            }

            const response = await fileUploadService.getFiles(startAt, itemsPerPage);

            if (!response) {
                return { pages: [], total: 0, hasNextPage: false };
            }
            const { total, pageItems } = response;
            return {
                pages: pageItems || [],
                hasNextPage,
                startAt: startAt + (pageItems.length || 0),
                total,
            };
        } catch (error) {
            console.error("Could not fetch files");
            throw error;
        }
    };

    const { data, isFetchingNextPage, fetchNextPage, isLoading } = useInfiniteQuery(["file-uploads"], fetchFiles, {
        refetchOnMount: false,
        keepPreviousData: false,
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage) => {
            const hasNextPage = lastPage?.hasNextPage || null;
            return { hasNextPage, startAt: lastPage?.startAt };
        },
    });

    const paginatedFiles = usePaginatedResultItems<{ pages: File[]; hasNextPage: boolean }, File>(
        data,
        (response) => response.pages,
    );

    return (
        <>
            <PageDescription
                title="Files"
                description="Files are used to upload files to the system."
                buttons={[
                    {
                        label: "Upload File",
                        icon: "fas fa-upload",
                        onClick: () => {
                            setShowAddFile(true);
                        },
                    },
                ]}
            />
            <AddFile show={showAddFile} close={() => setShowAddFile(false)} />
            {isLoading ? (
                <Loader2 />
            ) : (
                <div className="">
                    <div className="">
                        {paginatedFiles && (
                            <PaginatedView
                                onRequestToLoadMore={fetchNextPage}
                                isLoading={isFetchingNextPage}
                                currentCount={paginatedFiles.length}
                                // pages[0] because all pages have the same `total`
                                totalCount={data?.pages[0]?.total || 0}
                            >
                                <TableNeogen
                                    entries={(paginatedFiles ?? []).map((file) => ({
                                        id: file.id,
                                        name: file.originalFilename || "-",
                                        type: file.key?.entryField || "-",
                                        uploadedBy: file.uploaded_by || "-",
                                        dateUploaded: file.dateUploaded || "-",
                                    }))}
                                    headers={["File Name", "Type", "Uploaded by", "Date Uploaded"]}
                                    formatters={[
                                        {
                                            field: "uploadedBy",
                                            type: "User",
                                        },
                                        {
                                            field: "dateUploaded",
                                            type: "Date",
                                        },
                                    ]}
                                    ignoreFields={[
                                        "key",
                                        "isArchived",
                                        "encoding",
                                        "documentTypeId",
                                        "notes",
                                        "filename",
                                        "mimetype",
                                    ]}
                                    actionsAreDropDown={true}
                                    actions={[
                                        {
                                            label: "Download",
                                            icon: "fas fa-download",
                                            onClick: (entry) => {
                                                const file = paginatedFiles.find((file) => file.id === entry);
                                                if (file) {
                                                    fileService.downloadFile(file.filename, file.originalFilename);
                                                }
                                            },
                                        },
                                        {
                                            label: "Delete",
                                            icon: "fas fa-trash",
                                            className: "bg-red-500 text-white",
                                            onClick: (entry) => {
                                                Swal.fire({
                                                    title: "Are you sure?",
                                                    text: "You won't be able to revert this!",
                                                    icon: "warning",
                                                    showCancelButton: true,
                                                    confirmButtonColor: "#3085d6",
                                                    cancelButtonColor: "#d33",
                                                }).then((result) => {
                                                    if (result.value) {
                                                        fileUploadService.deleteByID(entry).then(() => {
                                                            queryCache.invalidateQueries(["files"]);
                                                            Swal.fire(
                                                                "Deleted!",
                                                                "Your file has been deleted.",
                                                                "success",
                                                            );
                                                        });
                                                    }
                                                });
                                            },
                                        },
                                    ]}
                                />
                            </PaginatedView>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
